<template>
    <div>
      <NavBar />
  
      <div class="main-div position-relative">
        <div class="width-100">
          <img
            src="./assets/bgP.jpg"
            alt="Group44527"
            class="width-100 height-38 object-fit-cover header-background-img"
          />
        </div>
  
        <div class="absolute top-1 left-12 custom-top-left">
          <div class="width-100">
            <img
              src="./assets/Group682x.png"
              alt="Group682x"
              class="width-10 custom-logo-img margin-top-1"
            />
          </div>
  
          <div class="display-flex flex-column width-100 margin-top-2">
            <h4 class="text-c-w margin-0 padding-0 text-2xl font-weight-400">
              Welcome To The
            </h4>
            <h1
              class="text-c-w margin-0 padding-0 text-4xl font-weight-300 text-size-ibtalks margin-y-1"
              style="line-height: 6rem"
            >
            The Phoenix Tale
            </h1>
  
            <div
              class="display-flex justify-content-start align-items-center gap-1 width-50 custom-width-para-div"
            >
              <div class="width-15" style="border-bottom: 2px solid gray"></div>
              <h5
                class="text-c-w margin-0 padding-0 text-x-larger para-text-size-bussiness-prodcast font-weight-400 text-default-size"
                style="line-height: 2rem"
              >
                The Business Stories
              </h5>
              <div class="width-15" style="border-bottom: 2px solid gray"></div>
            </div>
  
            <div
              class="display-flex align-items-center justify-content-center width-50 custom-width-para-div"
            >
              <p
                class="text-c-w text-large width-100 para-text-size font-weight-400"
              >
                The Business Stories" is a dynamic platform designed to engage
                listeners in insightful discussions surrounding the world of
                business. From entrepreneurial journeys and industry trends to
                leadership insights and innovative strategies, IBTalks offers a
                diverse range of topics aimed at inspiring, educating, and
                empowering individuals in the business sphere.
              </p>
            </div>
          </div>
        </div>
  
        <div class="display-flex justify-content-center flex-column width-100">
          <div
            class="display-flex justify-content-center align-items-center flex-column"
          >
            <h1
              class="text-2xl text-c-b font-weight-bold margin-0 padding-0"
              style="line-height: 6rem"
            >
            The Phoenix Tale
            </h1>
            <p class="text-1xl text-c-b font-weight-400 margin-0 padding-0">
            (The Business Stories)
            </p>
          </div>
  
          <div
            class="display-flex justify-content-center align-items-center flex-column margin-top-2 width-100"
          >
            <div
              class="display-flex justify-content-center margin-top-2 width-75 gap-3 custom-flex-col"
            >
              <img
                src="./assets/3FD93DEA-8999-428E-BD8A-EAC97CFB1F76.png"
                alt="3FD93DEA-8999-428E-BD8A-EAC97CFB1F76"
                class="width-40 custom-first-w-h"
              />
  
              <p>
                "The Phoenix Tale: The Business Stories" is a dynamic platform designed to
                engage listeners in insightful discussions surrounding the world
                of business. From entrepreneurial journeys and industry trends to
                leadership insights and innovative strategies, IBTalks offers a
                diverse range of topics aimed at inspiring, educating, and
                empowering individuals in the business sphere. Hosted by seasoned
                professionals and industry experts, each episode delves into
                relevant issues, shares valuable perspectives, and provides
                practical advice for navigating the complexities of modern
                business landscapes. Whether you're a seasoned entrepreneur,
                aspiring business leader, or simply curious about the intricacies
                of commerce, The Phoenix Tale promises to deliver compelling content that
                sparks thought, fosters growth, and drives meaningful
                conversations in the business community. Tune in and join the
                conversation as we explore the ever-evolving world of business
                together.
              </p>
            </div>
  
            <div
              class="display-flex justify-content-space-between align-items-center margin-top-2 width-75 gap-3 width-100 custom-flex-star-image"
            >
              <div class="display-flex flex-column width-100">
                <h1
                  class="text-3xl font-weight-bold margin-0 padding-0 custom-text-deco"
                  style="line-height: 6rem"
                >
                  Discover Your Favorite Podcasts
                </h1>
                <p class="text-1xl font-weight-400 margin-0 padding-0">
                  Open The Gateway To Infinite Podcast Possibilities: Immerse
                  Yourself In A Universe Of Diverse And Entertaining Content
                </p>
              </div>
  
              <img
                src="./assets/Group44499.svg"
                alt="Group44499"
                class="width-10"
                style=""
              />
            </div>
  
            <div
              class="display-flex flex-column width-75 gap-3 margin-y-4 custom-width-serch-div"
            >
              <div
                class="display-flex justify-content-space-between align-items-center width-100 link-group custom-flex-direction-rev"
              >
                <div class="display-flex gap-6 custom-gap-link">
                  <router-link
                    to="#"
                    class="text-1xl text-c-b font-weight-bold margin-0 padding-0 link-items"
                    @mouseover="changeDividerColor(true)"
                    @mouseleave="changeDividerColor(false)"
                     @click="getAllPodcasts('The Phoenix Tale')"
                  >
                    All The Phoenix Tale Stories
                  </router-link>
                  <router-link
                    to="#"
                    class="text-1xl text-c-b font-weight-bold margin-0 padding-0 link-items"
                    @mouseover="changeDividerColor(true)"
                    @mouseleave="changeDividerColor(false)"
                    @click="getAllPodcasts('Trending Stories')"

                  >
                    Trending Stories
                  </router-link>
                  <router-link
                    to="#"
                    class="text-1xl text-c-b font-weight-bold margin-0 padding-0 link-items"
                    @mouseover="changeDividerColor(true)"
                    @mouseleave="changeDividerColor(false)"
                    @click="getAllPodcasts('Stories Season 1')"

                  >
                    Season 1
                  </router-link>
                </div>
  
                <div class="search-div">
                  <input
                    v-model="search"
                    filled
                    type="search"
                    placeholder="Search Podcast"
                  />
                  <!-- <i class="fa-solid fa-magnifying-glass"></i> -->
                  <font-awesome-icon
                    :icon="['fas', 'magnifying-glass']"
                    class="search-icon-class"
                  />
                </div>
              </div>
              <div class="relative">
                <div
                  class="divider"
                  :style="{ borderBottom: dividerColor }"
                ></div>
              </div>
            </div>
          </div>
        </div>
  
        <div
          class="display-flex justify-content-center align-items-center flex-column width-100 gap-4"
        >
          <div
            v-for="podcast in allPodcasts"
            :key="podcast.id"
            class="display-flex align-items-center gap-4 width-75 custom-flex-col custom-padding content-coustom-width"
            style="background: #f3f1f4 0% 0% no-repeat padding-box"
          >
            <img
              v-if="podcast.image"
              :src="podcast.image"
              alt="welcome-to-the-IBtalks"
              class="custom-first-w-h"
            />
            <img
              v-else
              src="./assets/welcome-to-the-IBtalks.png"
              alt="welcome-to-the-IBtalks"
              class="width-40 custom-first-w-h"
            />
            <div class="display-flex flex-column gap-4">
              <div class="display-flex flex-column">
                <h4
                  class="text-2xl text-c-b font-weight-bold margin-top-1 margin-bottom-0 padding-0"
                >
                  {{ podcast.title }} : {{ podcast.name }}
                </h4>
                <p
                  class="text-1xl text-c-b font-weight-400 margin-top-1 padding-0"
                  style="text-align: justify; padding-right: 1rem"
                >
                  {{ podcast.description }}
                </p>
              </div>
  
              <div class="margin-y-1 watch-btn" style="width: 12em">
                <button
                  @click="redirect(podcast.link)"
                  class="width-100 display-flex justify-content-space-evenly align-items-center padding-2"
                  style="
                    background: #aae5ff 0% 0% no-repeat padding-box;
                    border: 2px solid #00b2ff;
                    cursor: pointer;
                  "
                >
                  WATCH NOW
                  <img
                    src="./assets/icons8-play-50.png"
                    alt="icons8-play-50"
                    width="15"
                    height="15"
                  />
                </button>
              </div>
            </div>
          </div>
  
          <!-- <div
        class="display-flex align-items-center gap-4 width-75 margin-y-2 custom-flex-col custom-padding content-coustom-width"
        style="background: #f3f1f4 0% 0% no-repeat padding-box"
      >
        <img
          src="./AssetsIBTalks/welcome-to-the-IBtalks.png"
          alt="welcome-to-the-IBtalks"
          class="width-40 custom-first-w-h"
        />
        <div class="display-flex flex-column gap-4">
          <div class="display-flex flex-column">
            <h4 class="text-2xl text-c-b font-weight-bold margin-0 padding-0">
              Ep 02: Driving Positive Change In The Business World
            </h4>
            <p class="text-1xl text-c-b font-weight-400 margin-0 padding-0">
              Beyond just profitability, businesses have the power to drive
              positive change in society and the world at large. Through
              thought-provoking discussions on topics such as corporate social
              responsibility, sustainability, and ethical leadership, "How To
              Impact On Business" inspires listeners to make a difference and
              leave a lasting legacy through their business endeavors.
            </p>
          </div>
  
          <div class="margin-y-1" style="width: 12em">
            <button
              @click="redirect(url2)"
              class="width-100 display-flex justify-content-space-evenly align-items-center padding-2"
              style="
                background: #aae5ff 0% 0% no-repeat padding-box;
                border: 2px solid #00b2ff;
                cursor: pointer;
              "
            >
              WATCH NOW
              <img
                src="./AssetsIBTalks/icons8-play-50.png"
                alt="icons8-play-50"
                width="15"
                height="15"
              />
            </button>
          </div>
        </div>
      </div>
  
      <div
        class="display-flex align-items-center gap-4 width-75 margin-y-2 custom-flex-col custom-padding content-coustom-width"
        style="background: #f3f1f4 0% 0% no-repeat padding-box"
      >
        <img
          src="./AssetsIBTalks/welcome-to-the-IBtalks.png"
          alt="welcome-to-the-IBtalks"
          class="width-40 custom-first-w-h"
        />
        <div class="display-flex flex-column gap-4">
          <div class="display-flex flex-column">
            <h4 class="text-2xl text-c-b font-weight-bold margin-0 padding-0">
              Ep 03: Empowering Entrepreneurs To Succeed
            </h4>
            <p class="text-1xl text-c-b font-weight-400 margin-0 padding-0">
              Whether you're a seasoned entrepreneur or just starting your
              journey, "How To Impact On Business" provides invaluable guidance
              and inspiration to help you succeed in your entrepreneurial
              endeavors. From launching a startup to scaling your business, our
              podcast covers the essential steps and strategies for building a
              thriving enterprise.
            </p>
          </div>
  
          <div class="margin-y-1" style="width: 12em">
            <button
              @click="redirect(url3)"
              class="width-100 display-flex justify-content-space-evenly align-items-center padding-2"
              style="
                background: #aae5ff 0% 0% no-repeat padding-box;
                border: 2px solid #00b2ff;
                cursor: pointer;
              "
            >
              WATCH NOW
              <img
                src="./AssetsIBTalks/icons8-play-50.png"
                alt="icons8-play-50"
                width="15"
                height="15"
              />
            </button>
          </div>
        </div>
      </div>
  
      <div
        class="display-flex align-items-center gap-4 width-75 margin-y-2 custom-flex-col custom-padding content-coustom-width"
        style="background: #f3f1f4 0% 0% no-repeat padding-box"
      >
        <img
          src="./AssetsIBTalks/welcome-to-the-IBtalks.png"
          alt="welcome-to-the-IBtalks"
          class="width-40 custom-first-w-h"
        />
        <div class="display-flex flex-column gap-4">
          <div class="display-flex flex-column">
            <h4 class="text-2xl text-c-b font-weight-bold margin-0 padding-0">
              Ep 04: Empowering Strategies For Business Growth
            </h4>
            <p class="text-1xl text-c-b font-weight-400 margin-0 padding-0">
              How To Impact On Business is your ultimate guide to discovering
              empowering strategies that drive tangible growth and success in
              the dynamic world of business. Through insightful discussions,
              expert insights, and practical advice, this podcast equips you
              with the tools and knowledge needed to make a meaningful impact on
              your business endeavors.
            </p>
          </div>
  
          <div class="margin-y-1" style="width: 12em">
            <button
              @click="redirect(url4)"
              class="width-100 display-flex justify-content-space-evenly align-items-center padding-2"
              style="
                background: #aae5ff 0% 0% no-repeat padding-box;
                border: 2px solid #00b2ff;
                cursor: pointer;
              "
            >
              WATCH NOW
              <img
                src="./AssetsIBTalks/icons8-play-50.png"
                alt="icons8-play-50"
                width="15"
                height="15"
              />
            </button>
          </div>
        </div>
      </div> -->
  
          <div
            class="display-flex justify-content-center align-items-center gap-4 width-75 margin-y-2 custom-flex-col custom-padding"
          >
            <button
              class="text-c-w padding-2 display-flex align-items-center justify-content-space-evenly"
              style="
                background: #004798 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                cursor: pointer;
                width: 8em;
              "
            >
              All Episode
              <img
                src="./assets/Icon-awesome-angle-right.svg"
                alt="Icon-awesome-angle-right"
                width="10"
              />
            </button>
          </div>
  
          <div class="blue-area-div"></div>
        </div>
  
        <div class="last-logo-div">
          <h2 style="font-size: 40px !important; margin: unset !important">
            <b>
              <span class="space1" style="color: #004898">Restro</span>
              <span class="space1" style="color: #c41e16">world</span>
            </b>
          </h2>
        </div>
      </div>
  
      <FooterComp />
    </div>
  </template>
  
  <script>
  import NavBar from "./DummyNavBar.vue";
  import "./style/Custom_Style.css";
  
  import "./style/IB_Talks_And_Blogs.css";
  
  import FooterComp from "../Footer/FooterComp.vue";
  
  import axios from "axios";
  
  export default {
    name: "DummyIB_Talks_Page",
    data() {
      return {
        dividerColor: "2px solid gray",
        widthAll: "",
        widthTp: "",
        widthSe: "",
        // url1: "https://www.youtube.com/watch?v=4gYnkR_QgxI&feature=youtu.be",
        url2: "",
        url3: "",
  
        url4: "",
  
        // base_url: "https://uatpodcast.infinitybrains.com/public/api/", // Uat Url
  
        base_url: "https://podcast.infinitybrains.com/public/api/", // Prod Url
  
        allPodcasts: [],
      };
    },
    components: {
      NavBar,
      FooterComp,
    },
    methods: {
      getAllPodcasts(title) {
      try {
        axios
          .get(`${this.base_url}readall-podcasts?order=desc&title=${title && title != '' ? title : "The Phoenix Tales"}`)
            .then((response) => {
              // console.log("Response of Podcasts: ", response);
              this.allPodcasts = response.data.data;
            });
        } catch (err) {
          console.error(err);
        }
      },
      changeDividerColor(isHovered) {
        if (isHovered) {
          this.dividerColor = "2px solid black";
        } else {
          this.dividerColor = "2px solid gray";
        }
      },
  
      redirect(url) {
        // console.log(url);
        window.location.href = url;
      },
    },
    mounted() {
      this.getAllPodcasts();
    },
  };
  </script>
  
  <style scoped>
  .last-logo-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
  }
  
  .last-logo-div img {
    width: 12rem;
  }
  
  .blue-area-div {
    width: 100%;
    height: 50px;
    margin-top: 24px;
    margin-bottom: 12px;
    background-color: #004798;
  }
  
  .main-div {
    top: 5.8rem;
  }
  
  .link-items {
    text-decoration: none;
    color: #333;
    transition: color 0.3s;
  }
  
  .divider {
    width: 100%;
    border-bottom: 2px solid gray;
    transition: border-color 0.3s;
  }
  
  .search-div {
    background-color: rgb(243, 241, 244);
    display: flex;
    height: 56px;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
  }
  
  .search-div input {
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.00937em;
    text-decoration: inherit;
    text-transform: inherit;
    border: none;
    border-radius: 0;
    background: none;
    color: rgba(0, 0, 0, 0.87);
    outline: 0;
    padding: 6px 0;
    margin: 0;
  }
  
  .search-div input:focus {
    border: none !important;
  }
  
  .search-div .search-icon-class {
    align-content: center;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.54);
    font-size: 18px;
    padding-left: 12px;
  }
  
  .custom-first-w-h {
    width: 35%;
    max-width: 30rem;
    margin-left: 1rem;
    height: 18em;
  }
  
  @media (max-width: 767px) {
    .custom-width-para-div {
      width: 100% !important;
    }
  
    .custom-flex-star-image {
      flex-direction: column;
    }
  
    .custom-flex-star-image img {
      width: 25%;
    }
  
    .custom-width-serch-div {
      width: 95%;
    }
  }
  
  @media (max-width: 1024px) {
    .main-div {
      top: 4.8rem;
    }
    .header-background-img {
      height: 37rem;
    }
  
    .custom-top-left {
      top: 2rem;
      left: 0rem;
      padding-left: 2rem;
    }
  
    .text-size-ibtalks {
      font-size: 4em;
    }
  
    .custom-width-para-div {
      width: 85%;
    }
  
    .custom-flex-col {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .custom-first-w-h {
      width: 75%;
      height: 25em;
      margin-left: 0rem;
    }
  
    .custom-padding {
      padding: 1em;
    }
  
    .custom-text-deco {
      line-height: 1em !important;
    }
  }
  
  @media (max-width: 992px) {
    .custom-flex-direction-rev {
      flex-direction: column-reverse;
    }
  
    .search-div {
      width: 90%;
      margin-bottom: 2em;
    }
  
    .search-div input {
      width: 90%;
    }
  }
  
  @media (max-width: 650px) {
    .custom-first-w-h {
      width: 100% !important;
      object-fit: inherit;
    }
  
    .watch-btn {
      margin: 0.2rem auto;
    }
  }
  
  @media (max-width: 450px) {
    .header-background-img {
      height: 38rem;
    }
  
    .custom-top-left {
      top: 0.5rem;
    }
    .para-text-size {
      font-size: 1em !important;
    }
  
    .text-size-ibtalks {
      line-height: 1em !important;
    }
  
    .para-text-size-bussiness-prodcast {
      font-size: 2em !important;
    }
  
    .content-coustom-width {
      width: 100%;
    }
  
    .custom-gap-link {
      gap: 2rem;
    }
  
    .custom-logo-img {
      width: 20%;
    }
  }
  
  @media only screen and (min-width: 1640px) {
    .header-background-img {
      height: 40rem;
    }
    .custom-top-left {
      top: 1rem;
    }
  }
  </style>